export const validateEmail = (email) => {

  if (String(email)
    .toLowerCase().includes('@')) {
    return true
  } else {
    return false
  }
  // return String(email)
  //   .toLowerCase()
  //   .match(
  //     /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  //   );
};

// 不少于8位字符，且必须包含一个数字和一个特殊字符
export const passwordPattern =
  /^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z\d~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{6,32}/;
export const checkIsEmail = (email) => {
  // /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  // debugger
  const pattern = /^.*@.*$/;
  const isEmail = pattern.test(email);
  return isEmail;
};

export const checkIsPassword = (password) => {
  const isPassword = passwordPattern.test(password);
  return isPassword;
};

export const checkTwoPassword = (password, newPassword) => password === newPassword;

export const checkFormFn = {
  email: checkIsEmail,
  password: checkIsPassword,
  newPassword: checkIsPassword,
};

export const emptyMessageList = {
  name: 'Please enter your username',
  email: 'Please enter your email',
  password: 'Please enter your password',
  newPassword: 'Please enter new password',
  part: 'Please enter your information',
  all: 'Please enter your information',
};
export const errorMessageList = {
  email: 'Please enter a valid email address',
  password: 'Password must be 6 to 32 characters long and must contain letters and numbers',
  newPassword: 'Password must be 6 to 32 characters long and must contain letters and numbers',
  more: 'Please check the information format',
};

export const checkForm = (values, isNotCheckFormate) =>
  new Promise((resolve, reject) => {
    const keys = Object.keys(values);

    let message = '';
    const emptyKey = [];
    const errorKey = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (!values[keys[i]]) {
        emptyKey.push(keys[i]);
      } else {
        const checkResult = checkFormFn[keys[i]] && checkFormFn[keys[i]](values[keys[i]]);
        if (checkResult === false) {
          errorKey.push(keys[i]);
        }
      }
    }

    if (emptyKey.length) {
      if (emptyKey.length === keys.length && emptyKey.length >= 3) {
        message = emptyMessageList.all;
      } else if (emptyKey.length > 2 && emptyKey.length < keys.length) {
        message = emptyMessageList.part;
      } else if (emptyKey.length === 2) {
        const emptyStr = emptyKey.join(' and ');
        message = `Please complete the ${emptyStr}`;
      } else {
        message = emptyMessageList[emptyKey[0]];
      }

      reject({
        errorFiles: [...emptyKey],
        message,
      });
    } else {
      if (isNotCheckFormate) {
        resolve();
      }
      if (!errorKey.length) {
        resolve();
      }

      message = errorMessageList[errorKey[0]];
      reject({
        errorFiles: [...errorKey],
        message,
      });
    }
  });

export const checkField = () => new Promise(() => { });