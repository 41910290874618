<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<script setup>
import { reactive, ref, watch, h, computed } from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import prefix_name from "@/assets/user-name.svg";
import prefix_email from "@/assets/prefix_email.svg";
import prefix_password from "@/assets/prefix_password.svg";
import prefix_name_fail from "@/assets/fail_username.svg";
import prefix_email_fail from "@/assets/fail_email.svg";
import prefix_password_fail from "@/assets/fail_password.svg";
import { useDebounceFn } from "@vueuse/core";
import { checkForm } from "./utils.js";
import login_congratulation from '../../assets/login/appsumo_icon_congratulation.svg'
import { useUserStore, useSubscriptionInfo } from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute()
const user = useUserStore();
const subscriptionInfo = useSubscriptionInfo()
const formState = reactive({
  name: "",
  email: "",
  password: "",
  exist: ""
});
const registerButtonDisabled = ref(false);
const tipsList = ref([
  {
    id: 1,
    content: '☑️ Unlimited access to all Pro features'
  }, {
    id: 2,
    content: '☑️ 10 batch processing / month'
  }, {
    id: 3,
    content: '☑️ Priority support'
  }, {
    id: 4,
    content: '☑️ All future releases of Pro features'
  }
])
watch(() => route.query, (val) => {
  formState.email = val.email
  formState.exist = val.exist
  if (val.package_code == '200901') {
    tipsList.value = [
      {
        id: 1,
        content: '☑️ Unlimited access to all Ultra features'
      }, {
        id: 2,
        content: '☑️ Unlimited batch processing'
      }, {
        id: 3,
        content: '☑️ 60 mins video processing credits / month'
      }, {
        id: 4,
        content: '☑️ Priority support'
      }, {
        id: 5,
        content: '☑️ All future releases of Ultra features'
      },
    ]
  } else {
    tipsList.value = [
      {
        id: 1,
        content: '☑️ Unlimited access to all Pro features'
      }, {
        id: 2,
        content: '☑️ 10 batch processing / month'
      }, {
        id: 3,
        content: '☑️ Priority support'
      }, {
        id: 4,
        content: '☑️ All future releases of Pro features'
      }
    ]
  }

}, {
  deep: true,
  immediate: true
})

watch(formState, () => {
  /* 深层级变更状态所触发的回调 */
  if (formState.exist == 'true') {
    // 登录

    if (formState.email && formState.password) {
      registerButtonDisabled.value = false;
    } else {
      registerButtonDisabled.value = true
    }
    return
  }

  if (formState.email && formState.name && formState.password) {
    registerButtonDisabled.value = false;
  } else {
    registerButtonDisabled.value = true
  }
})

const isLoading = ref(false)
const errorState = reactive({
  name: "success",
  email: "success",
  password: "success",
  errorMsg: "",
});

const step = ref(1);


//init errorState
const initErrorState = () => {
  errorState.name = "success";
  errorState.email = "success";
  errorState.password = "success";
  errorState.errorMsg = "";
};


const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
    color: '#fff',
    marginRight: '8px'
  },
  spin: true,
});


const loginFn = async () => {
  isLoading.value = true
  let res = null
  if (route.query.exist == 'true') {
    // 老用户直接登录
    res = await user.appSumoLogin({
      username: formState.email,
      password: formState.password,
    });
  } else {

    // 新用户注册登录
    res = await user.appSumoRegister({
      username: formState.name,
      password: formState.password,
      email: formState.email,
    });

  }
  // 获取appsumo用户的当前权益
  await subscriptionInfo.refresh()
  isLoading.value = false
  const { code, msg } = res;
  if (code !== 0) {
    errorState.errorMsg = msg;
  } else {
    step.value = 2;
  }
};

const debouncedFn = useDebounceFn(() => {
  loginFn();
}, 500);

const onFinish = (values) => {
  checkForm(values, false)
    .then(() => {
      initErrorState();
      debouncedFn(values);
    })
    .catch((errorInfo) => {
      errorState.errorMsg = errorInfo.message;
      errorState.name = errorInfo.errorFiles.includes("name")
        ? "error"
        : "success";
      errorState.email = errorInfo.errorFiles.includes("email")
        ? "error"
        : "success";
      errorState.password = errorInfo.errorFiles.includes("password")
        ? "error"
        : "success";
    });
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const handleToHomePage = () => {
  router.push({
    path: '/',
  })
}

</script>

<template>
  <div>
    <a-form v-if="step === 1" :model="formState"
      class="login-form  flex-1 flex justify-center items-center flex-col" name="basic" autocomplete="off"
      @finish="onFinish" @finishFailed="onFinishFailed">
      <a-form-item class="mb-6">
        <h1 class="text-black text-2xl font-medium">Welcome to Booltool</h1>
      </a-form-item>
      <a-form-item class="pb-6">
        <div class="text-gray-400 subTip">
          <span v-if="formState.exist == 'true'">
            Looks like you already have an account,
            please confirm your password.
          </span>
          <span v-else>
            Please set your password down below to get started with Booltool.
          </span>
        </div>
      </a-form-item>
      <a-form-item name="name" class="mb-7" :validateStatus="errorState.name" v-if="formState.exist == 'false'">
        <a-input v-model:value="formState.name" class="form-input" placeholder="Name">
          <template #prefix>
            <img :src="prefix_name" v-if="errorState.name === 'success'" />
            <img :src="prefix_name_fail" v-else />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="email" class="mb-7" :validateStatus="errorState.email">
        <a-input v-model:value="formState.email" class="form-input" placeholder="Email" disabled>
          <template #prefix>
            <img :src="prefix_email" v-if="errorState.email === 'success'" />
            <img :src="prefix_email_fail" v-else />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="password" class="mb-3" :validateStatus="errorState.password">
        <a-input-password v-model:value="formState.password" class="form-input" placeholder="Password">
          <template #prefix>
            <img :src="prefix_password" v-if="errorState.password === 'success'" />
            <img :src="prefix_password_fail" v-else />
          </template>
        </a-input-password>
      </a-form-item>
      <!-- 错误信息展示，常驻 -->

      <a-form-item class="mb-3 error-msg-item">
        <div class="error-msg-box">{{ errorState.errorMsg }}</div>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="registerButtonDisabled" class="form-button">
          <a-spin :indicator="indicator" v-if="isLoading" />
          <span>
            Continue with
            email
          </span></a-button>
      </a-form-item>
    </a-form>
    <div v-if="step === 2" class="success-tab">
      <div class="success-tab-head">
        <img :src="login_congratulation" />
        <p class="header-title">Congratulations !</p>
        <p class="header-subtitle">Your AppSumo {{ route.query.package_code == '200901' ? 'Tire2' : 'Tire1' }} plan
          has been activated
          successfully.
        </p>
      </div>

      <p v-for="item in tipsList" :key="item.id" class="tip-item">
        {{ item.content }}
      </p>

      <button class="try-on-btn" @click="handleToHomePage">
        Try now
      </button>
    </div>
    <div class="text-inc-color pb-10">
      Agree to the
      <router-link to="/terms" class="underline">Terms of Service</router-link>,
      <router-link to="/privacy" class="underline">Privacy Policy</router-link>,
      and
      <router-link to="/cookies" class="underline">Cookie Policy</router-link>.
    </div>
  </div>
</template>

<style lang="less" scoped>
.login-form {
  width: 100%;
  max-width: 375px;
}

.subTip {
  font-size: 16px;
  max-width: 375px;
}

.success-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;

  p {
    width: 100%;
    line-height: 41px;
    text-align: left;
  }
}

.success-tab-head {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: 'Inter', Arial;

  img {
    width: 54px;
    height: 54px;
  }

  .header-title {
    font-family: 'Inter', Arial;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin: 14px 0 24px 0;
    text-align: center;
  }

  .header-subtitle {
    margin-bottom: 64px;
    line-height: 24px;
  }

}

.tip-item {
  padding: 0 50px;
  font-family: 'Inter', Arial;
}

.ant-form-item {
  width: 100%;
}

.try-on-btn {

  display: flex;
  justify-content: center;
  width: 375px;
  height: 54px;
  border-radius: 36px;
  background: #060606;
  align-items: center;
  line-height: 24px;
  border: 1px solid #060606;
  color: #fff;
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  margin-top: 64px;

  &:hover {
    background: #2f2f2f;
  }
}

.form-input {
  width: 100%;
  min-width: 100%;
  height: 54px;
  border: 1px solid #d0d0d0;
  border-radius: 41px;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
}

@media screen and (any-hover: none) {
  a:hover {
    color: unset;
    text-decoration: underline;
  }
}
</style>
