<script setup>
import { ref, watchEffect, onMounted, onBeforeUnmount, computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import login from './login-form.vue'
import appsumo_login_image from '../../assets/login/appsumo_login_image.png'
import { eventTracking } from '@/utils/eventTracking.js'
import { useRoute } from 'vue-router'
import charactersImage from '@/assets/login-page-bgimg-characters.webp'
import { tailwindConfig as tailwindSharedConfig } from '@/shared.config.js'

const route = useRoute()
const containerHeight = ref(innerHeight)
const { width: innerWidth } = useWindowSize()
const isPC = computed(() => tailwindSharedConfig.screens.m.max < innerWidth.value)

//监听当前路由变化，变化就切到相对应的组件
const currentTab = ref('login');

watchEffect(
  () => {
    currentTab.value = route.path.replace("/", "")
  }
)

function handleResize() {
  containerHeight.value = innerHeight
}
  
onMounted(() => {
  eventTracking('booltool_signup_view')
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div class="h-full flex justify-center login-page" :style="{minHeight: `${containerHeight - .01}px`}">
    <div v-if="isPC" class="left-box flex-1 flex justify-center align-center ">
      <div class="login-page-bgimg">
        <!-- <img :src="greenImg" class="login-page-green-img" alt=""> -->
        <img :src="charactersImage" class="login-page-bgimg-characters" />
      </div>

      <div class="left-info ">
        <div class="welcome-box">
          <h1 class="font-medium">An All-in-one Suite Of Amazing Tool </h1>
        </div>

        <div class="welcome-text-wrap">
          <img :src="appsumo_login_image" />
        </div>
      </div>
    </div>
    <login class="relative right-box flex-1 flex justify-center items-center" style="padding: 60px 24px 0"/>

  </div>
</template>
<style lang="less" scoped>
.right-box {
  min-width: 390px;
  flex-direction: column;
}

.left-box {
  overflow: hidden;
  min-width: 565px;
  min-height: 765px;
  position: relative;
}

.left-info {
  color: white !important;
  z-index: 9;

}

.login-page-bgimg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/login/appsumo_login_bg.png');
  background-size: cover;

  .login-page-orange-img {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 550px;
    width: 15vw;
    min-width: 162px;
  }

  .login-page-green-img {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 205px;
    width: 13vw;
    min-width: 141px;
  }

  .login-page-bgimg-characters {
    max-width: 450px;
    position: absolute;
    width: 35vw;
    right: 0;
    bottom: 0;
    min-width: 350px;
  }
}

.welcome-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  // width: 125px;



  h1 {
    padding: 0 56px 0 86px;

    // min-width: 467px;
    max-width: 800px;
    font-size: 48px;
    line-height: 74px;
    color: white;
    text-align: left;
    // margin-left: 12px;
  }
}

.welcome-text-wrap {
  margin-top: 30px;
  padding: 0 30px;
  max-width: 800px;

  img {}

}

.tools-wrap-title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  line-height: 53px;
}

.marginL {
  margin-left: 50px;
}

.ml {
  margin-top: 28px;
  margin-left: 50px;
}

@media only screen and (max-width: 1200px) {
  .left-info {
    margin-top: 125px;
  }
}


@media only screen and (min-width: 1200px) {
  .left-info {
    margin-top: 60px;
  }
}



@media only screen and (max-width: 1200px) and (max-height: 800px) {
  .left-info {
    margin-top: 60px;
  }

}

@media only screen and (min-width: 1200px) and (max-height: 800px) {
  .left-info {
    margin-top: 30px;
  }

  .welcome-text-wrap {
    margin-top: 0px;
  }
}
</style>
